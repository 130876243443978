import { LitElement, PropertyValues, css, html } from 'lit'
import { customElement, property, query, queryAll } from 'lit/decorators.js';

type Image = HTMLImageElement

@customElement('image-loop')
export class ImageLoop extends LitElement {
  @property({ type: Number })
  delay = 3

  get lastElement() {
    return this.childElementCount - 1;
  }

  get count() {
    return this.childElementCount
  }

  get totalTime() {
    return this.delay * this.lastElement
  }

  get totalTimes() {
    return Array(this.count).fill(0).map((_, i) => {
      return i * this.delay
    })
  }


  addEventListeners() {
    const children = Array.from(this.children) as Array<HTMLImageElement>

    children.forEach((element, i) => {
      element.classList.add('animated')
      element.style = `animation-delay: ${this.totalTimes[i]}s;`
      element.setAttribute('slot', 'children');

      element.addEventListener('animationend', () => {
        element.classList.remove('animated')
        void element.offsetWidth; // Reflow!
        element.style = `animation-delay: ${this.totalTime - (this.delay * 1)}s;`
        element.classList.add('animated')
      })
    });
  }

  render() {
    this.addEventListeners()

    return html`<slot name='children'></slot>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'image-loop': ImageLoop
  }
}